import store from '../store/index'

class StatsService {
    axios
    baseUrl
    
    constructor(axios, apiUrl) {
        this.axios = axios
        this.baseUrl = `${apiUrl}Stats`
    }
    
    GetExpensesGroupByCategory(year) {
        let self = this;
        self.axios.defaults.headers.common.Authorization = 'Bearer ' + store.getters.user.token   
        return self.axios.get(`${self.baseUrl}/GetExpensesGroupByCategory/?year=${year}`);
    }
    GetExpensesGroupByType(year) {
        let self = this;
        self.axios.defaults.headers.common.Authorization = 'Bearer ' + store.getters.user.token   
        return self.axios.get(`${self.baseUrl}/GetExpensesGroupByType/?year=${year}`);
    }

    GetExpensesGroupByTypeSpent(year, month) {
        let self = this;
        self.axios.defaults.headers.common.Authorization = 'Bearer ' + store.getters.user.token   
        return self.axios.get(`${self.baseUrl}/GetExpensesGroupByTypeSpent/?year=${year}&month=${month}`);
    }

    GetStatsIndicator() {
        let self = this;
        self.axios.defaults.headers.common.Authorization = 'Bearer ' + store.getters.user.token   
        return self.axios.get(`${self.baseUrl}/GetStatsIndicator`);
    }

}

export default StatsService