<template>
  <div>
    <v-container pb-0>
      <v-layout row wrap>
        <v-flex xs12 md12>
          <v-card class="pa-2 mr-2" tile>
            <div class="d-flex align-baseline">
              <div
                class="blue--text px-2 py-1 text-capitalize font-weight-medium"
              >
                Gastos por tipo de pago
              </div>
              <div class="ml-2 mt-2">
                <v-autocomplete
                  :items="years"
                  dense
                  outlined
                  label="Año"
                  v-model="selectedYearTipoPago"
                  @change="loaddataTipo"
                  @keydown="$event.target.blur()"
                  @keypress="$event.target.blur()"
                  @keyup="$event.target.blur()"
                ></v-autocomplete>
              </div>
            </div>
            <v-divider></v-divider>
            <v-container>
              <v-layout row wrap my-0>
                <v-flex xs12 md6 mt-4>
                  <v-data-table
                    height="280px"
                    :headers="headersType"
                    :items="yearlyExpensesType"
                    sort-by="date"
                    :items-per-page="5"
                    loading-text="Loading... Please wait"
                    dense
                    :footer-props="{ itemsPerPageOptions: [10] }"
                  >
                    <template v-slot:[`item.value`]="{ item }">
                      <span>{{ item.value | currency }}</span>
                    </template>
                  </v-data-table>
                </v-flex>
                <v-flex xs12 md6 style="min-height:340px; height=100%">
                  <StackChart :chartData="chartDataType" />
                </v-flex>
              </v-layout>
            </v-container>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import apiServices from "../Services/apiServices";
import StackChart from "../components/Charts/StackChart";

export default {
  props: {
    years: {
      type: Array,
      default() {
        return [];
      },
    },
    theme: {
      type: String,
    },
  },
  components: { StackChart },
  mounted() {
    this.loaddataTipo(this.selectedYearTipoPago);
  },
  computed: {},
  methods: {
    loaddataTipo(year) {
      this.loadyearlydataType(year).then(() => {
        this.loadcategoryStackType(year);
      });
    },
    loadyearlydataType(year) {
      year;

      return apiServices.StatsService.GetExpensesGroupByType(
        this.selectedYearTipoPago
      ).then((response) => {
        this.fulldataType = response.data;
        var data = this.fulldataType["expenses"];
        data.forEach(function (part) {
          part.date = part.date.substr(0, 10);
          return part.date;
        });
        this.yearlyExpensesType = data;
      });
    },
    loadcategoryStackType(year) {
      year;
      this.chartDataType = {
        labels: this.fulldataType["labels"],
        datasets: this.fulldataType["datasets"],
      };
    },
  },
  data: () => ({
    selectedYearTipoPago: new Date().getFullYear(),
    yearlyExpensesType: [],
    fulldataType: {},

    headersType: [
      { text: "Tipo", value: "type" },
      { text: "Fecha", value: "date" },
      { text: "Cantidad", value: "value" },
    ],
    chartDataType: {},
  }),
};
</script>

<style></style>
