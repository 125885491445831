import Vue from 'vue'
import VueRouter from 'vue-router'
import Expenses from '../views/Expenses.vue'
import CurrentExpenses from '../views/CurrentExpenses.vue'
import DeferredExpenses from '../views/DeferredExpenses.vue'
import PaymentsType from '../views/PaymentsType.vue'
import ExpensesCategory from '../views/ExpensesCategory.vue'
import Information from '../views/Information.vue'
import Login from '../components/Login.vue'
import Register from '../components/Register.vue'
import PasswordRecovery from '../components/PasswordRecovery.vue'
//import Home from '../views/Home.vue'
//import Dashboards from '../views/Dashboards.vue'
import store from '../store/index'
import Stats from "../views/Stats.vue"


Vue.use(VueRouter)

const routes = [

  {
    path: '/login',
    name: 'Login',
    component: Login,
    meta: {
      hideForAuth: true
  }
  },
  {
    path: '/register',
    name: 'Register',
    component: Register,
    meta: {
      hideForAuth: true
  }
  },
  {
    path: '/passwordRecovery',
    name: 'PasswordRecovery',
    component: PasswordRecovery,
    meta: {
      hideForAuth: true
  }
  },
  {
    path: '/paymentsType',
    name: 'PaymentsType',
    component: PaymentsType,
    meta: {
      requiresAuth: true
    }
  },  
  {
    path: '/expensesCategory',
    name: 'ExpensesCategory',
    component: ExpensesCategory,
    meta: {
      requiresAuth: true
    }
  },  
  {
    path: '/expenses',
    name: 'Expenses',
    component: Expenses,
    meta: {
      requiresAuth: true
    }
  },  
  {
    path: '/currentexpenses',
    name: 'CurrentExpenses',
    component: CurrentExpenses,
    meta: {
      requiresAuth: true
    }
  },  
  {
    path: '/deferredexpenses',
    name: 'DeferredExpenses',
    component: DeferredExpenses,
    meta: {
      requiresAuth: true
    }
  },  
  {
    path: '/information',
    name: 'Information',
    component: Information,
    meta: {
      requiresAuth: true
    }
  },
 /* {
    path: '/dashboards',
    name: 'Dashboards',
    component: Dashboards,
    meta: {
      requiresAuth: true
    }
  },*/
  {
    path: '/stats',
    name: 'Stats',
    component: Stats,
    meta: {
      requiresAuth: true
    }
  },
  
  
  /*{ path: '', name: 'Home', component: Home, meta: {
    hideForAuth: true
} },*/
  { path: '/', name: 'Login', component: Login, meta: {
    hideForAuth: true
} },
  { path: '*', redirect: '/' }
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
  
})
//https://stackoverflow.com/questions/52653337/vuejs-redirect-from-login-register-to-home-if-already-loggedin-redirect-from
router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    // this route requires auth, check if logged in
    // if not, redirect to login page.    
    if (!store.getters.user.loggedIn) {
      next({ name: 'Login' })
    } else {
      if(store.getters.user.loggedInAll){
      next() // go to wherever I'm going
      }      
    }
  } else {
    next() // does not require auth, make sure to always call next()!
  }
})


export default router
