<template>
  <div class="information">
    <LoadingProcess :loadingShow="loadingShow" :loadingTitle="loadingTitle" />
    <v-card flat>
      <v-row class="mt-5">
        <v-toolbar-title>
          <h3 class="subheading grey--text ml-3">Mis datos</h3>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <template v-if="!isMobile">
          <v-btn
            rounded
            dark
            @click="editInformation()"
            color="primary"
            class="mr-5"
            small
          >
            <v-icon left>edit</v-icon>
            Editar
          </v-btn>
        </template>
        <template v-else>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                dark
                large
                @click="editInformation()"
                color="blue"
                class="mr-5"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>edit</v-icon>
              </v-btn>
            </template>
            <span>Editar</span>
          </v-tooltip>
        </template>
      </v-row>
      <v-card elevation="24" class="mt-5">
        <v-form ref="form" @submit.prevent="submit" class="mx-4">
          <v-card-text>
            <v-container>
              <v-layout row wrap justify-space-around>
                <v-flex xs11 md12>
                  <v-text-field
                    v-model="form.displayName"
                    :rules="rules.displayName"
                    label="Nombre Completo"
                    placeholder="Introduce el nombre completo"
                    outlined
                    dense
                    readonly
                    class="input-group--focused"
                    append-icon="person"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout row wrap justify-space-around>
                <v-flex xs11 md12>
                  <v-text-field
                    v-model="form.email"
                    :rules="rules.email"
                    label="Email"
                    outlined
                    dense
                    readonly
                    class="input-group--focused"
                    append-icon="mail"
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-container>
          </v-card-text>
        </v-form>
      </v-card>

      <v-dialog
        v-model="EditInformationDialog"
        width="600"
        persistent
        transition="dialog-bottom-transition"
        scrollable
      >
        <EditInformation
          :infoSelected="userSelected"
          @closeEditInformation="closeWinEditInformation"
        />
      </v-dialog>
    </v-card>
    <v-snackbar v-model="snackbar" bottom>
      <!-- <v-icon dark> mdi-checkbox-marked-circle </v-icon>-->
      {{ messageSnackbar }}
      <template v-slot:action="{ attrs }">
        <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
          Cerrar
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import EditInformation from "../components/EditInformation.vue";
import apiServices from "../Services/apiServices";
import helperServices from "../Services/helperServices";
import LoadingProcess from "../components/LoadingProcess.vue";
//var mercadopago = require("mercadopago");

export default {
  components: { EditInformation, LoadingProcess },
  data() {
    return {
      useruid: "",
      userSelected: [],
      form: {
        id: "",
        displayName: "",
        email: "",
      },
      id: -1,
      show3: false,
      rules: {
        //name: [(val) => (val || "").length > 0 || "Nombre requerido."],
        //email: [(val) => (val || "").length > 0 || "Email requerido."],
      },
      EditInformationDialog: false,
      snackbar: false,
      messageSnackbar: "",
      isMobile: false,
      loadingShow: false,
      loadingTitle: "",
    };
  },
  computed: {
    //...mapGetters({
    // map `this.user` to `this.$store.getters.user`
    //  user: "user",
    // }),
  },

  async created() {
    this.loadingTitle = "Cargando mis datos...";

    this.loadInformationUsertoBD();
    this.isMobile = helperServices.MobileService.isMobile();
  },

  methods: {
    initialize() {},
    editInformation() {
      this.EditInformationDialog = true;
    },
    closeWinEditInformation(result) {
      if (result.tipo == "cerrar") {
        this.EditInformationDialog = result.isVisible;
      } else if (result.tipo == "editar") {
        this.loadInformationUsertoBD();
        this.EditInformationDialog = result.isVisible;
        //this.snackbar = true;
        //this.messageSnackbar = "Datos modificados correctamente.";
        this.$fire({
          position: "top-end",
          title: "Datos modificados correctamente!",
          // text: "Datos modificados correctamente!",
          type: "success",
          timer: 3000,
          toast: true,
          showConfirmButton: false,
        }).then(() => {
          //console.log(r.value);
        });
        //this.getPatients();
        // this.showSnackbar = true;
      }
    },
    loadInformationUserStore() {
      this.userSelected = this.$store.getters.user.data;

      if (this.userSelected) {
        this.form.displayName = this.userSelected.displayName;
        this.form.email = this.userSelected.email;
        this.form.specialty = this.userSelected.specialty;
        this.form.phone = this.userSelected.phone;
        // this.userExist = true;
      }
    },
    loadInformationUsertoBD() {
      this.loadingShow = true;
      this.userSelected = this.$store.getters.user.data;
      apiServices.userService
        .GetUserByEmail(this.userSelected.email)
        .then((item) => {
          this.form.id = item.data.id;
          this.form.displayName = item.data.userName;
          this.form.email = this.userSelected.email;
          this.form.specialty = item.data.specialty;
          this.form.phone = item.data.phone;
          this.loadingShow = false;
        })
        .catch((error) => {
          this.loadingShow = false;
          if (error.response) {
            // get response with a status code not in range 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // no response
            console.log(error.request);
            // instance of XMLHttpRequest in the browser
            // instance ofhttp.ClientRequest in node.js
          } else {
            // Something wrong in setting up the request
            console.log("Error", error.message);
          }
          console.log("Error.config: " + error.config);
        });
    },
  },
};
</script>
