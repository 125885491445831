import store from '../store/index'

class ExpenseService {
    axios
    baseUrl
    
    constructor(axios, apiUrl) {
        this.axios = axios
        this.baseUrl = `${apiUrl}Expenses`
    }
    
    GetExpenseById(id) {
        let self = this;
        self.axios.defaults.headers.common.Authorization = 'Bearer ' + store.getters.user.token   
        return self.axios.get(`${self.baseUrl}/GetExpenseById/?id=${id}`);
    }

    GetExpenses() {        
        let self = this;   
        
        self.axios.defaults.headers.common.Authorization = 'Bearer ' + store.getters.user.token   
        return self.axios.get(`${self.baseUrl}/GetExpenses`);
          /*{
            headers: {
              'Authorization': "Bearer " + token
            }
          }*/
    }
    GetExpensesPagination(filter) {        
      let self = this;   
      
      self.axios.defaults.headers.common.Authorization = 'Bearer ' + store.getters.user.token   
      return self.axios.post(`${self.baseUrl}/GetExpenses`,filter);
        /*{
          headers: {
            'Authorization': "Bearer " + token
          }
        }*/
  }

  GetCurrentExpensesPagination(filter) {        
    let self = this;   
    
    self.axios.defaults.headers.common.Authorization = 'Bearer ' + store.getters.user.token   
    return self.axios.post(`${self.baseUrl}/GetCurrentExpenses`,filter);
      /*{
        headers: {
          'Authorization': "Bearer " + token
        }
      }*/
}

GetDeferredExpensesPagination(filter) {        
  let self = this;   
  
  self.axios.defaults.headers.common.Authorization = 'Bearer ' + store.getters.user.token   
  return self.axios.post(`${self.baseUrl}/GetDeferredExpenses`,filter);
    /*{
      headers: {
        'Authorization': "Bearer " + token
      }
    }*/
}

    /*getAll(params) {        
        let self = this;           
        self.axios.defaults.headers.common.Authorization = 'Bearer ' + store.getters.user.token.token   
        return self.axios.get(`${self.baseUrl}`,{
                params
          });
          /*{
            headers: {
              'Authorization': "Bearer " + token
            }
          }
    }*/

    InsertExpense(model) {
        let self = this;
        self.axios.defaults.headers.common.Authorization = 'Bearer ' + store.getters.user.token   
        return self.axios.post(`${self.baseUrl}/InsertExpense`, model);
    }

    UpdateExpense(model) {
        let self = this;
        self.axios.defaults.headers.common.Authorization = 'Bearer ' + store.getters.user.token   
        return self.axios.put(`${self.baseUrl}/UpdateExpense`, model);
    }

    DeleteExpense(id) {
        let self = this;
        self.axios.defaults.headers.common.Authorization = 'Bearer ' + store.getters.user.token   
        return self.axios.delete(`${self.baseUrl}/DeleteExpense/${id}`);
    }

    GetStatsExpenses() {
      let self = this;
      self.axios.defaults.headers.common.Authorization = 'Bearer ' + store.getters.user.token   
      return self.axios.get(`${self.baseUrl}/GetStatsExpenses`);
  }

  GetStatsCurrentExpenses() {
    let self = this;
    self.axios.defaults.headers.common.Authorization = 'Bearer ' + store.getters.user.token   
    return self.axios.get(`${self.baseUrl}/GetStatsCurrentExpenses`);
}
GetStatsDeferredExpenses() {
  let self = this;
  self.axios.defaults.headers.common.Authorization = 'Bearer ' + store.getters.user.token   
  return self.axios.get(`${self.baseUrl}/GetStatsDeferredExpenses`);
}
}

export default ExpenseService