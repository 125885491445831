<template ref="refToElement">
  <div class="ExpensesCategory">
    <LoadingProcess :loadingShow="loadingShow" :loadingTitle="loadingTitle" />
    <v-card flat>
      <v-row class="mt-5">
        <v-toolbar-title>
          <h3 class="headline grey--text ml-3">Categorias</h3>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <template v-if="!isMobile">
          <v-btn
            rounded
            dark
            @click="addItem()"
            color="primary"
            class="mr-5"
            small
          >
            <v-icon left>add</v-icon>
            Nuevo
          </v-btn>

          <v-btn
            rounded
            dark
            @click="printer()"
            color="black"
            class="mr-5"
            small
          >
            <v-icon left>print</v-icon>
            Imprimir
          </v-btn>
        </template>
        <template v-else>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                dark
                large
                @click="addItem()"
                color="primary"
                class="mr-5"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>add</v-icon>
              </v-btn>
            </template>
            <span>Nuevo</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                icon
                dark
                large
                @click="printer()"
                color="black"
                class="mr-5"
                v-bind="attrs"
                v-on="on"
              >
                <v-icon>print</v-icon>
              </v-btn>
            </template>
            <span>Imprimir</span>
          </v-tooltip>
        </template>
      </v-row>
      <v-card class="mt-5">
        <div>
          <v-data-table
            :headers="headers"
            :items="items"
            :server-items-length="totalItems"
            :options.sync="options"
            sort-by="name"
            :search="search"
            @update:options="getItems"
            fixed-header
          >
            <template v-slot:top>
              <v-toolbar flat>
                <!-- <v-toolbar-title>Lista de pacientes</v-toolbar-title>
             <v-divider class="mx-4" inset vertical></v-divider>
                <v-card-subtitle>Lista de pacientes</v-card-subtitle> -->
                <v-spacer></v-spacer>
                <v-spacer></v-spacer>
                <v-text-field
                  v-model="search"
                  append-icon="search"
                  label="Buscar categoria..."
                  single-line
                  hide-details
                  class="p-0 m-0 mt-6 mb-4"
                  full-width
                  dense
                  outlined                  
                  placeholder="Escribe para filtrar los datos"
                  @change="getItems"
                ></v-text-field>
                <!-- <v-spacer></v-spacer> 
            <v-btn fab small dark color="primary" @click="addItem()">
              <v-icon>add</v-icon>
            </v-btn>-->
                <v-dialog v-model="dialogDelete" max-width="500px">
                  <v-card>
                    <v-card-title>Eliminar</v-card-title>
                    <v-card-subtitle
                      >Desea eliminar la categoria
                      {{ itemSelected.Name }} ?</v-card-subtitle
                    >
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn text @click="closeDelete">Cancelar</v-btn>
                      <v-btn color="error" @click="deleteItemConfirm"
                        >Eliminar</v-btn
                      >
                      <v-spacer></v-spacer>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-toolbar>
            </template>
            <template v-slot:[`item.budget`]="{ item }">
              <span>{{ item.budget | currency }}</span>
            </template>
            <template v-slot:[`item.colourHex`]="{ item }">
              <v-chip
                :color="item.colourHex"
                style="padding: 0px; height: 20px; width: 20px"
                flat
                small
                class="ml-1 mb-1"
              ></v-chip>
            </template>
            <template v-slot:[`item.actions`]="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    v-bind="attrs"
                    v-on="on"
                    medium
                    class="mr-2"
                    color="blue darken-2"
                    @click.stop="editItem(item)"
                  >
                    edit
                  </v-icon>
                </template>
                <span>Editar</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    small
                    v-bind="attrs"
                    v-on="on"
                    medium
                    class="mr-2"
                    color="red darken-2"
                    @click.stop="deleteItem(item)"
                  >
                    delete
                  </v-icon>
                </template>
                <span>Eliminar</span>
              </v-tooltip>
            </template>
            <template v-slot:no-data>
              <v-btn color="primary" @click="initialize"> Reset </v-btn>
            </template>
          </v-data-table>

          <v-snackbar v-model="snackbar" bottom>
            <!-- <v-icon dark> mdi-checkbox-marked-circle </v-icon>-->
            {{ messageSnackbar }}
            <template v-slot:action="{ attrs }">
              <v-btn color="pink" text v-bind="attrs" @click="snackbar = false">
                Cerrar
              </v-btn>
            </template>
          </v-snackbar>
          <v-dialog
            v-model="AddItemDialog"
            width="500px"
            persistent
            transition="dialog-bottom-transition"
            scrollable
          >
            <EditExpenseCategory
              :itemSelected="itemSelected"
              :headerTitle="headerTitle"
              :buttonTitle="buttonTitle"
              @closeEditItem="closeWinEditItem"
            />
          </v-dialog>
        </div>
      </v-card>
    </v-card>
  </div>
</template>

<script>
import apiServices from "../Services/apiServices";
import helperServices from "../Services/helperServices";
import EditExpenseCategory from "../components/EditExpenseCategory.vue";
import LoadingProcess from "../components/LoadingProcess.vue";

//https://sweetalert2.github.io/

export default {
  components: { EditExpenseCategory, LoadingProcess },

  data() {
    const defaultForm = Object.freeze({
      id: -1,
      name: "",
      description: "",
      Budget: 0,
      ColourHex: "",
    });
    return {
      itemSelected: Object.assign({}, defaultForm),
      AddItemDialog: false,
      headerTitle: "",
      buttonTitle: "",
      search: "",
      dialogDelete: false,
      // dialogInfo: false,
      headers: [
        {
          text: "Nombre",
          align: "start",

          value: "name",
        },
        { text: "Descripción", value: "description" },
        { text: "Presupuesto", value: "budget" },
        { text: "Color", value: "colourHex" },
        { text: "Acciones", value: "actions", sortable: false },
      ],
      items: [],
      options: {},
      totalItems: 0,
      itemsPerPage: 0,
      page: 0,
      snackbar: false,
      messageSnackbar: "",
      infoItem: {
        name: "",
        description: "",
        budget: 0,
        colourHex: "",
      },
      loadingShow: false,
      loadingTitle: "",
      isMobile: false,
    };
  },

  watch: {
    dialogDelete(val) {
      val || this.closeDelete();
    },
    options: {
      handler() {
        this.getItems;
      },
      deep: true,
    },
  },

  mounted() {
    //this.useruid = this.$store.getters.user.data.uid;
    this.loadingTitle = "Cargando lista...";

    this.isMobile = helperServices.MobileService.isMobile();
  },

  methods: {
    initialize() {},

    getItems() {
      const { page, itemsPerPage } = this.options;

      // sortBy,sortDesc
      var filter = {
        searchText: this.search,
        userUID: null,
        PageSize: itemsPerPage,
        PageNumber: page,
      };

      this.loadingShow = true;
      apiServices.ExpenseCategoryService.GetExpensesCategoryPagination(filter)
        .then((resp) => {
          this.items = resp.data.data;
          this.totalItems = resp.data.meta.totalCount;
          //  this.currentPage = resp.data.meta.currentPage;
          this.loadingShow = false;
        })
        .catch((error) => {
          this.loadingShow = false;
          if (error.response) {
            // get response with a status code not in range 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // no response
            console.log(error.request);
            // instance of XMLHttpRequest in the browser
            // instance ofhttp.ClientRequest in node.js
          } else {
            // Something wrong in setting up the request
            console.log("Error", error.message);
          }
          console.log("Error.config: " + error.config);
        });
    },
    addItem() {
      this.headerTitle = "Agregar categoria";
      this.buttonTitle = "Guardar";
      this.itemSelected = Object.assign({}, this.defaultForm);
      this.itemSelected.id = -1;
      this.AddItemDialog = true;
    },
    editItem(item) {
      this.headerTitle = "Editar categoria";
      this.buttonTitle = "Actualizar";
      this.itemSelected = item;
      this.AddItemDialog = true;
    },

    deleteItem(item) {
      this.itemSelected = item;
      this.$fire({
        title: "Desea eliminar la categoria?",
        text: item.Name,
        type: "question",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Aceptar",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        // console.log(result);
        if (result.value === true) {
          this.deleteItemConfirm();
          this.$fire({
            position: "top-end",
            title: "Categoria eliminada correctamente!",
            // text: "El registro fue eliminado de la base de datos",
            type: "success",
            timer: 3000,
            toast: true,
            showConfirmButton: false,
          }).then(() => {
            //console.log(r.value);
          });
        }
      });
    },

    deleteItemConfirm() {
      if (this.itemSelected) {
        apiServices.ExpenseCategoryService.DeleteExpenseCategory(
          this.itemSelected.id
        )
          .then(() => {
            this.search = "";
            this.getItems();
          })
          .catch((error) => {
            if (error.response) {
              // get response with a status code not in range 2xx
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              // no response
              console.log(error.request);
              // instance of XMLHttpRequest in the browser
              // instance ofhttp.ClientRequest in node.js
            } else {
              // Something wrong in setting up the request
              console.log("Error", error.message);
            }
            console.log("Error.config: " + error.config);
          });
      }
      this.closeDelete();
    },
    closeDelete() {
      this.dialogDelete = false;
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem);
        this.editedIndex = -1;
      });
    },

    closeWinEditItem(result) {
      if (result.tipo == "cerrar") {
        this.AddItemDialog = result.isVisible;
      } else if (result.tipo == "nuevo") {
        this.AddItemDialog = result.isVisible;

        this.$fire({
          position: "top-end",
          title: "Categoria creada correctamente",
          // text: "Operación exitosa!!!",
          type: "success",
          timer: 3000,
          toast: true,
          showConfirmButton: false,
        }).then(() => {
          //console.log(r.value);
        });
        this.getItems();
      } else if (result.tipo == "editar") {
        this.AddItemDialog = result.isVisible;
        this.getItems();
        //https://www.npmjs.com/package/vue-simple-alert
        this.$fire({
          position: "top-end",
          title: "Categoria modificada correctamente",
          // text: "Edición exitosa!!!",
          type: "success",
          timer: 3000,
          toast: true,
          //grow:'row',
          //width: 300,
          showConfirmButton: false,
        }).then(() => {
          //console.log(r.value);
        });
      }
    },
    printer() {
      //parametros para imprimir
      var titleDoc = "Reporte Expenses Control"; //titulo de la pestaña, nuevo documento
      var titleReport = "Lista de categorias"; //titulo del reporte
      //datos para imprimir
      var dataSelectedToPrint = this.items;
      //orden de las columnas, debe hacer match con los nombres de la coleccion de datos
      var columnsDisplayOrderArray = [
        "name",
        "description",
        "budget",
        "colourHex",
      ];
      //tamaño de las columnas, en el mismo orden que columnsDisplayOrderArray
      var customColumnsWidthsArray = ["auto", "auto", "auto", "auto"];
      //orden de las columnas de la cabecera de la tabla a imprimir
      var customHeadersArrays = [
        {
          text: "Nombre",
          // fillColor: "red",
          // color: "white",
          alignment: "left",
          alignmentChild: "right",
          bold: true,
        },
        {
          text: "Descripción",
          // fillColor: "red",
          // color: "white",
          alignment: "left",
          alignmentChild: "right",
          bold: true,
        },
        {
          text: "Presupuesto",
          // fillColor: "red",
          // color: "white",
          alignment: "left",
          alignmentChild: "right",
          bold: true,
        },
        {
          text: "Color Hex",
          // fillColor: "red",
          // color: "white",
          alignment: "left",
          alignmentChild: "right",
          bold: true,
        },
      ];
      var orientationDescription = "Landscape"; // Portrait or Landscape.
      var pageSizeDescription = "A4";
      // itemsSelected = itemsSelectedfromDay.sort((a, b) => {
      //   return b.start - a.start;
      //  });

      //se llama al metodo imprimir
      helperServices.PrintService.printer(
        titleDoc,
        titleReport,
        new Date().toISOString().substr(0, 10),
        dataSelectedToPrint,
        columnsDisplayOrderArray,
        customColumnsWidthsArray,
        customHeadersArrays,
        orientationDescription,
        pageSizeDescription
      );
    },
  },
};
</script>
<style lang="css" scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
