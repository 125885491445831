<template>
  <div>
    <v-card>
      <v-sheet outlined color="blue" rounded="0" max-height="13">
        <br />
      </v-sheet>
      <v-toolbar flat>
        <v-toolbar-title> {{ headerTitle }} </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon bottom right offset-y @click="closeWindow('cerrar', '')">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-sheet
        id="scrolling-techniques-7"
        class="overflow-y-auto"
        max-height="450px"
      >
        <v-container style="height: 900px">
          <v-form ref="itemSelected" @submit.prevent="submit">
            <v-container>
              <v-layout row wrap justify-space-around>
                <v-flex xs11 md12>
                  <v-text-field
                    type="date"
                    label="Fecha"
                    v-model="itemSelected.date"
                    :rules="rules.date"
                    :format="format"
                    required
                    outlined
                    dense
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout row wrap justify-space-around>
                <v-flex xs11 md12>
                  <v-autocomplete
                    v-model="itemSelected.category"
                    :items="itemsCategory"
                    label="Categoria"
                    item-text="name"
                    item-value="name"
                    :rules="rules.Category"
                    required
                    outlined
                    dense
                    placeholder="Escribe la categoria"
                  ></v-autocomplete>
                </v-flex>
              </v-layout>
              <v-layout row wrap justify-space-around>
                <v-flex xs11 md12>
                  <v-autocomplete
                    v-model="itemSelected.type"
                    :items="itemsType"
                    label="Tipo de pago"
                    item-text="name"
                    item-value="name"
                    :rules="rules.Type"
                    required
                    outlined
                    dense
                    placeholder="Escribe el tipo de pago"
                  ></v-autocomplete>
                </v-flex>
              </v-layout>
              <v-layout row wrap justify-space-around>
                <v-flex xs11 md12>
                  <v-text-field
                    v-model="itemSelected.value"
                    :rules="rules.value"
                    color="blue darken-2"
                    label="Cantidad"
                    required
                    outlined
                    dense
                    min="0"
                    step=".1"
                    type="number"
                    placeholder="Introduce la cantidad"
                  ></v-text-field>
                </v-flex>
              </v-layout>
                  <v-layout v-if="this.typeExpense=='deferred'" row wrap justify-space-around>
                <v-flex xs11 md12>
                  <v-text-field
                    v-model="itemSelected.totalMonth"
                    :rules="rules.totalMonth"
                    color="blue darken-2"
                    label="Meses sin intereses"
                    required
                    outlined
                    dense
                    min="0"
                    step=".1"
                    type="number"
                    placeholder="Introduce la cantidad"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout row wrap justify-space-around>
                <v-flex xs11 md12>
                  <v-text-field
                    v-model="itemSelected.comments"
                    :rules="rules.comments"
                    color="blue darken-2"
                    label="Comentarios"
                    required
                    outlined
                    dense
                    placeholder="Introduce la descripción"
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-container>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                :disabled="!formIsValid"
                :loading="isLoading"
                color="primary"
                type="submit"
                elevation="24"
                rounded
                small
              >
                <v-icon left> save </v-icon>
                {{ buttonTitle }}
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-container>
      </v-sheet>
    </v-card>
  </div>
</template>
<script>
import apiServices from "../Services/apiServices";
//import moment from "moment";

export default {
  data() {
    const defaultForm = Object.freeze({
      name: "",
      description: "",
    });

    return {
      form: Object.assign({}, defaultForm),
      rules: {
        date: [(val) => (val || "").length > 0 || ""],
        category: [
          (val) => (val || "").length > 0 || "Categoria es requerida.",
        ],
        type: [(val) => (val || "").length > 0 || "Tipo es requerido."],
        value: [(val) => (val || "").length > 0 || "Cantidad es requerida."],
         totalMonth: [(val) => (val || "").length > 0 || "Cantidad es requerida."],
        comments: [
          (val) => (val || "").length > 0 || "Comentarios son requeridos.",
        ],
      },
      snackbar: false,
      defaultForm,
      isLoading: false,
      itemsCategory: [],
      itemsType: [],
    };
  },
  props: {
    itemSelected: null,
    headerTitle: String,
    buttonTitle: String,
    typeExpense: String,
  },
  computed: {
    formIsValid() {
      return (
        this.itemSelected.date &&
        this.itemSelected.category &&
        this.itemSelected.type &&
        this.itemSelected.value &&
        //this.itemSelected.totalMonth &&
        this.itemSelected.comments
      );
    },
  },
  mounted() {},

  methods: {
    resetForm() {
      this.itemSelected = Object.assign({}, this.defaultForm);
      this.$refs.itemSelected.reset();
    },

    submit() {
      this.isLoading = true;
      let item = "";
      if(this.typeExpense === 'normal')
        {
          item = {
            id: 0,
            date: this.itemSelected.date,
            category: this.itemSelected.category,
            type: this.itemSelected.type,
            value: this.itemSelected.value,
            totalMonth: 0,
            comments: this.itemSelected.comments,
          };
        }
        else if(this.typeExpense === 'current')
      {
          item = {
            id: 0,
            date: this.itemSelected.date,
            category: this.itemSelected.category,
            type: this.itemSelected.type,
            value: this.itemSelected.value,
            isCurrent: 1,
            totalMonth: 0,
            comments: this.itemSelected.comments,
          };
        }
        else if(this.typeExpense === 'deferred')
      {
          item = {
            id: 0,
            date: this.itemSelected.date,
            category: this.itemSelected.category,
            type: this.itemSelected.type,
            value: this.itemSelected.value,
            isDeferred: 1,
            totalMonth: this.itemSelected.totalMonth,
            comments: this.itemSelected.comments,
          };
        }

      if (this.itemSelected.id == -1) {
        //Se inserta un nuevo registro
        apiServices.ExpenseService.InsertExpense(item)
          .then(() => {
            this.closeWindow("nuevo", "");
            this.isLoading = false;
          })
          .catch((error) => {
            this.isLoading = false;
            if (error.response) {
              // get response with a status code not in range 2xx
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              // no response
              console.log(error.request);
              // instance of XMLHttpRequest in the browser
              // instance ofhttp.ClientRequest in node.js
            } else {
              // Something wrong in setting up the request
              console.log("Error", error.message);
            }
            console.log("Error.config: " + error.config);
          });

        // item["fechaCreacion"] = new Date();

        //db.collection("patients").add(item);
      } else {
        item["id"] = this.itemSelected.id;
        apiServices.ExpenseService.UpdateExpense(item)
          .then(() => {
            this.closeWindow("editar", item);
            this.isLoading = false;
          })
          .catch((error) => {
            this.isLoading = false;
            if (error.response) {
              // get response with a status code not in range 2xx
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              // no response
              console.log(error.request);
              // instance of XMLHttpRequest in the browser
              // instance ofhttp.ClientRequest in node.js
            } else {
              // Something wrong in setting up the request
              console.log("Error", error.message);
            }
            console.log("Error.config: " + error.config);
          });
      }
    },
    getItemsCategory() {
      apiServices.ExpenseCategoryService.GetExpensesCategory()
        .then((resp) => {
          this.itemsCategory = resp.data;
        })
        .catch((error) => {
          this.loadingShow = false;
          if (error.response) {
            // get response with a status code not in range 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // no response
            console.log(error.request);
            // instance of XMLHttpRequest in the browser
            // instance ofhttp.ClientRequest in node.js
          } else {
            // Something wrong in setting up the request
            console.log("Error", error.message);
          }
          console.log("Error.config: " + error.config);
        });
    },
    getItemsType() {
      apiServices.PaymentTypeService.GetPaymentsType()
        .then((resp) => {
          this.itemsType = resp.data;
        })
        .catch((error) => {
          this.loadingShow = false;
          if (error.response) {
            // get response with a status code not in range 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // no response
            console.log(error.request);
            // instance of XMLHttpRequest in the browser
            // instance ofhttp.ClientRequest in node.js
          } else {
            // Something wrong in setting up the request
            console.log("Error", error.message);
          }
          console.log("Error.config: " + error.config);
        });
    },
    format(value, event) {
      event;
      return new Date(value).toISOString().split("T")[0]; //moment(String(value)).format("YYYY-MM-DD");
    },
    closeWindow(value, item) {
      if (value == "nuevo") {
        let result = {
          isVisible: false,
          tipo: "nuevo",
        };
        this.$emit("closeEditItem", result);
      } else if (value == "editar") {
        let result = {
          isVisible: false,
          tipo: "editar",
          item: item,
        };
        this.$emit("closeEditItem", result);
      } else if (value == "cerrar") {
        let result = {
          isVisible: false,
          tipo: "cerrar",
        };
        this.$emit("closeEditItem", result);
      }
    },
  },
  created() {
    this.getItemsCategory();
    this.getItemsType();
  },
};
</script>
