<template>
  <div>
    <v-container class="mt-5">
      <v-layout row>
        <v-flex xs12 sm6 md3>
          <v-card class="pa-4 mr-2" outlined elevation="20">
            <div class="text-overline mb-2">Gasto Total</div>
            <div class="text-h6 blue--text text-capitalize">
              {{ overallSpent | currency }}
            </div>
          </v-card>
        </v-flex>
        <v-flex xs12 sm6 md3>
          <v-card
            :class="{
              'pa-4 mt-2 mr-2': $vuetify.breakpoint.xs,
              'pa-4 mr-2': $vuetify.breakpoint.smAndUp,
            }"
            outlined
            elevation="20"
          >
            <div class="text-overline mb-2">Más gastado por</div>
            <div class="text-h6 blue--text text-capitalize">
              {{ mostSpentBy }}
            </div>
          </v-card>
        </v-flex>
        <v-flex xs12 sm6 md3>
          <v-card
            :class="{
              'pa-4 mt-2 mr-2': $vuetify.breakpoint.smAndDown,
              'pa-4 mr-2': $vuetify.breakpoint.mdAndUp,
            }"
            outlined
            elevation="20"
          >
            <div class="text-overline mb-2">Más gastado en</div>
            <div class="text-h6 blue--text text-capitalize">
              {{ mostSpentOn }}
            </div>
          </v-card>
        </v-flex>
        <v-flex xs12 sm6 md3>
          <v-card
            :class="{
              'pa-4 mt-2 mr-2': $vuetify.breakpoint.xs,
              'pa-4 mr-2 mt-2': $vuetify.breakpoint.sm,
              'pa-4 mr-2': $vuetify.breakpoint.mdAndUp,
            }"
            outlined
            elevation="20"
          >
            <div class="text-overline mb-2">Gastado en año actual</div>
            <div class="text-h6 blue--text text-capitalize">
              {{ spentThisYear | currency }}
            </div>
          </v-card>
        </v-flex>

        <v-flex xs12 md12 my-3>
          <v-card class="pa-2 mr-2" tile>
            <div class="d-flex align-baseline">
              <div class="text-h6 blue--text text-capitalize">
                Mes actual {{ TotalMonth | currency }}
              </div>
            </div>

            <v-row dense justify="space-between">
              <v-col cols="12" md="4" v-for="card in cards" :key="card.name">
                <v-card :color="card.color" dark height="120">
                   <div class="d-flex flex-no-wrap justify-space-between">
                        <div>
                          <v-card-title class="text-h5">
                            <div class="text-overline white--text">
                              {{ card.name }}
                            </div>
                          </v-card-title>

                          <v-card-subtitle>
                            <div class="text-overline white--text">
                              {{ card.dateRange }}
                            </div>
                            <div class="text-h6 white--text text-capitalize">
                              {{ card.total | currency }}
                            </div>
                          </v-card-subtitle>
                        </div>

                        <v-img v-if="!isMobile"
                          class="ma-3"
                          contain
                          max-width="100"
                          max-height="100"
                          :src="require(`@/assets/${card.pathImage}`)"
                        >
                        </v-img>
                        <v-img v-else
                          class="ma-3"
                          contain
                          max-width="60"
                          max-height="60"
                          :src="require(`@/assets/${card.pathImage}`)"
                        >
                        </v-img>
                      </div>
                </v-card>
              </v-col>
            </v-row>
          </v-card>
        </v-flex>

        <!--<v-flex xs12 sm6 md3>
          <v-card class="pa-4 mr-2 mt-2" outlined :color="tdc1.color">
            <div class="text-overline white--text mb-2">{{ tdc1.name }}</div>
            <div class="text-overline white--text mb-2">
              {{ tdc1.dateRange }}
            </div>
            <div class="text-h6 white--text text-capitalize">
              {{ tdc1.total | currency }}
            </div>
          </v-card>
        </v-flex>
        <v-flex xs12 sm6 md3>
          <v-card class="pa-4 mr-2 mt-2" outlined :color="tdc2.color">
            <div class="text-overline white--text mb-2">{{ tdc2.name }}</div>
            <div class="text-overline white--text mb-2">
              {{ tdc2.dateRange }}
            </div>
            <div class="text-h6 white--text text-capitalize">
              {{ tdc2.total | currency }}
            </div>
          </v-card>
        </v-flex>
        <v-flex xs12 sm6 md3>
          <v-card class="pa-4 mr-2 mt-2" outlined :color="tdc3.color">
            <div class="text-overline white--text mb-2">{{ tdc3.name }}</div>
            <div class="text-overline white--text mb-2">
              {{ tdc3.dateRange }}
            </div>
            <div class="text-h6 white--text text-capitalize">
              {{ tdc3.total | currency }}
            </div>
          </v-card>
        </v-flex>
        <v-flex xs12 sm6 md3>
          <v-card class="pa-4 mr-2 mt-2" outlined :color="tdc4.color">
            <div class="text-overline white--text mb-2">{{ tdc4.name }}</div>
            <div class="text-overline white--text mb-2">
              {{ tdc4.dateRange }}
            </div>
            <div class="text-h6 white--text text-capitalize">
              {{ tdc4.total | currency }}
            </div>
          </v-card>
        </v-flex>-->
        <v-flex xs12 md12 my-3>
          <v-flex>
            <ExpenseTypeStats :years="getYears()" :months="months" />
          </v-flex>
        </v-flex>
        <v-flex xs12 md12 my-3>
          <v-flex>
            <ExpensesStats :years="getYears()" />
          </v-flex>
        </v-flex>
        <v-flex xs12 md12 my-3>
          <v-flex>
            <ExpensesStatsByType :years="getYears()" />
          </v-flex>
        </v-flex>
        <!--   <v-flex xs12 md12 my-3>
          <v-flex>
            <ExpenseCategoryStats :years="getYears()" :months="months" />
          </v-flex>
        </v-flex>-->
      </v-layout>
    </v-container>
  </div>
</template>
<script>
import apiServices from "../Services/apiServices";
import ExpensesStats from "@/components/ExpensesStats";
import ExpensesStatsByType from "@/components/ExpensesStatsByType";
//import ExpenseCategoryStats from "@/components/ExpenseCategoryStats";
import ExpenseTypeStats from "@/components/ExpenseTypeStats";
import helperServices from "../Services/helperServices";

export default {
  components: {
    ExpensesStats,
    ExpensesStatsByType,
    ExpenseTypeStats,
  },
  methods: {
    getYears() {
      var items = [];
      var startYear = new Date().getFullYear() - 4;
      for (var i = 0; i < 7; i++) {
        items.push(startYear++);
      }
      return items;
    },
    loadData() {
      return apiServices.StatsService.GetStatsIndicator().then((response) => {
        this.overallSpent = response.data["overallSpent"];
        this.mostSpentBy = response.data["mostSpentBy"];
        this.mostSpentOn = response.data["mostSpentOn"];
        this.spentThisYear = response.data["spentThisYear"];
        this.cards = response.data["cardInfo"];

        let sum = 0;
        this.cards.forEach((card) => {
          sum += parseFloat(card.total);
        });
        this.TotalMonth = sum;
        //this.tdc1 = response.data["cardInfo"][0];
        //this.tdc2 = response.data["cardInfo"][1];
        //this.tdc3 = response.data["cardInfo"][2];
        //this.tdc4 = response.data["cardInfo"][3];
      });
    },
  },

  computed: {},
  data: () => ({
    months: [
      { name: "Todos", value: 0 },
      { name: "Enero", value: 1 },
      { name: "Febrero", value: 2 },
      { name: "Marzo", value: 3 },
      { name: "Abril", value: 4 },
      { name: "Mayo", value: 5 },
      { name: "Junio", value: 6 },
      { name: "Julio", value: 7 },
      { name: "Agosto", value: 8 },
      { name: "Septiembre", value: 9 },
      { name: "Octubre", value: 10 },
      { name: "Noviembre", value: 11 },
      { name: "Didciembre", value: 12 },
    ],
    overallSpent: "",
    mostSpentBy: "",
    mostSpentOn: "",
    spentThisYear: "",
    cards: [],
    TotalMonth: "",
    isMobile: false,
  }),
  mounted() {
    this.loadData();
    this.isMobile = helperServices.MobileService.isMobile();
  },
};
</script>

<style scoped>
::v-deep .v-text-field__details {
  display: none;
}
::v-deep .v-data-footer .v-data-footer__select .v-input {
  margin: 0;
  margin-left: 10px;
}
::v-deep .v-select__slot {
  font-size: smaller;
  width: 100px;
}
::v-deep .v-text-field > .v-input__control > .v-input__slot:before {
  border: 0px;
}

::v-deep .v-select__slot input {
  width: 50px;
}
</style>
