<template>
  <div>
    <v-card>
      <v-sheet outlined color="blue" rounded="0" max-height="13">
        <br />
      </v-sheet>
      <v-toolbar flat>
        <v-toolbar-title> {{ headerTitle }} </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon bottom right offset-y @click="closeWindow('cerrar', '')">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>

      <v-sheet
        id="scrolling-techniques-7"
        class="overflow-y-auto"
        max-height="450px"
      >
        <v-container style="height: 900px">
          <v-form ref="itemSelected" @submit.prevent="submit">
            <v-container>
              <v-layout row wrap justify-space-around>
                <v-flex xs11 md12>
                  <v-text-field
                    class="mt-5"
                    v-model="itemSelected.name"
                    :rules="rules.name"
                    color="purple darken-2"
                    label="Nombre"
                    required
                    outlined
                    dense
                    placeholder="Introduce el nombre"
                    append-icon="face"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout row wrap justify-space-around>
                <v-flex xs11 md12>
                  <v-text-field
                    v-model="itemSelected.description"
                    :rules="rules.description"
                    color="blue darken-2"
                    label="Descripcion"
                    required
                    outlined
                    dense
                    placeholder="Introduce la descripción"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout row wrap justify-space-around>
                <v-flex xs11 md12>
                  <v-text-field
                    v-model="itemSelected.colourHex"
                    :rules="rules.colourHex"
                    color="blue darken-2"
                    label="Color Hex"
                    required
                    outlined
                    dense
                    placeholder="Introduce el color hexadecimal"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout row wrap justify-space-around>
                <v-flex xs11 md12>
                  <v-text-field
                    v-model="itemSelected.dueDay"
                    color="blue darken-2"
                    label="Día de corte"
                    outlined
                    dense
                    type="number"
                    placeholder="Introduce el día de corte"
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-container>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                :disabled="!formIsValid"
                :loading="isLoading"
                color="primary"
                type="submit"
                elevation="24"
                rounded
                small
              >
                <v-icon left> save </v-icon>
                {{ buttonTitle }}
              </v-btn>
            </v-card-actions>
          </v-form>
        </v-container>
      </v-sheet>
    </v-card>
  </div>
</template>
<script>
import apiServices from "../Services/apiServices";

export default {
  data() {
    const defaultForm = Object.freeze({
      name: "",
      description: "",
    });

    return {
      form: Object.assign({}, defaultForm),
      rules: {
        name: [(val) => (val || "").length > 0 || "Nombre es requerido."],
        description: [
          (val) => (val || "").length > 0 || "Descripción es requerida.",
        ],
        colourHex: [(val) => (val || "").length > 0 || "Color es requerido."],
      },
      snackbar: false,
      defaultForm,
      isLoading: false,
    };
  },
  props: {
    itemSelected: null,
    headerTitle: String,
    buttonTitle: String,
  },
  computed: {
    formIsValid() {
      return (
        this.itemSelected.name &&
        this.itemSelected.description &&
        this.itemSelected.colourHex
      );
    },
  },
  mounted() {},

  methods: {
    resetForm() {
      this.itemSelected = Object.assign({}, this.defaultForm);
      this.$refs.itemSelected.reset();
    },

    submit() {
      this.isLoading = true;
      let item = {
        id: 0,
        name: this.itemSelected.name,
        description: this.itemSelected.description,
        colourHex: this.itemSelected.colourHex,
        dueDay: this.itemSelected.dueDay,
      };

      if (this.itemSelected.id == -1) {
        //Se inserta un nuevo registro
        apiServices.PaymentTypeService.InsertPaymentType(item)
          .then(() => {
            this.closeWindow("nuevo", "");
            this.isLoading = false;
          })
          .catch((error) => {
            this.isLoading = false;
            if (error.response) {
              // get response with a status code not in range 2xx
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              // no response
              console.log(error.request);
              // instance of XMLHttpRequest in the browser
              // instance ofhttp.ClientRequest in node.js
            } else {
              // Something wrong in setting up the request
              console.log("Error", error.message);
            }
            console.log("Error.config: " + error.config);
          });

        // item["fechaCreacion"] = new Date();

        //db.collection("patients").add(item);
      } else {
        item["id"] = this.itemSelected.id;
        apiServices.PaymentTypeService.UpdatePaymentType(item)
          .then(() => {
            this.closeWindow("editar", item);
            this.isLoading = false;
          })
          .catch((error) => {
            this.isLoading = false;
            if (error.response) {
              // get response with a status code not in range 2xx
              console.log(error.response.data);
              console.log(error.response.status);
              console.log(error.response.headers);
            } else if (error.request) {
              // no response
              console.log(error.request);
              // instance of XMLHttpRequest in the browser
              // instance ofhttp.ClientRequest in node.js
            } else {
              // Something wrong in setting up the request
              console.log("Error", error.message);
            }
            console.log("Error.config: " + error.config);
          });
      }
    },
    closeWindow(value, item) {
      if (value == "nuevo") {
        let result = {
          isVisible: false,
          tipo: "nuevo",
        };
        this.$emit("closeEditItem", result);
      } else if (value == "editar") {
        let result = {
          isVisible: false,
          tipo: "editar",
          item: item,
        };
        this.$emit("closeEditItem", result);
      } else if (value == "cerrar") {
        let result = {
          isVisible: false,
          tipo: "cerrar",
        };
        this.$emit("closeEditItem", result);
      }
    },
  },
  created() {},
};
</script>
