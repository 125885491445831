var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('v-container',{staticClass:"mt-5"},[_c('v-layout',{attrs:{"row":""}},[_c('v-flex',{attrs:{"xs12":"","sm6":"","md3":""}},[_c('v-card',{staticClass:"pa-4 mr-2",attrs:{"outlined":"","elevation":"20"}},[_c('div',{staticClass:"text-overline mb-2"},[_vm._v("Gasto Total")]),_c('div',{staticClass:"text-h6 blue--text text-capitalize"},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.overallSpent))+" ")])])],1),_c('v-flex',{attrs:{"xs12":"","sm6":"","md3":""}},[_c('v-card',{class:{
            'pa-4 mt-2 mr-2': _vm.$vuetify.breakpoint.xs,
            'pa-4 mr-2': _vm.$vuetify.breakpoint.smAndUp,
          },attrs:{"outlined":"","elevation":"20"}},[_c('div',{staticClass:"text-overline mb-2"},[_vm._v("Más gastado por")]),_c('div',{staticClass:"text-h6 blue--text text-capitalize"},[_vm._v(" "+_vm._s(_vm.mostSpentBy)+" ")])])],1),_c('v-flex',{attrs:{"xs12":"","sm6":"","md3":""}},[_c('v-card',{class:{
            'pa-4 mt-2 mr-2': _vm.$vuetify.breakpoint.smAndDown,
            'pa-4 mr-2': _vm.$vuetify.breakpoint.mdAndUp,
          },attrs:{"outlined":"","elevation":"20"}},[_c('div',{staticClass:"text-overline mb-2"},[_vm._v("Más gastado en")]),_c('div',{staticClass:"text-h6 blue--text text-capitalize"},[_vm._v(" "+_vm._s(_vm.mostSpentOn)+" ")])])],1),_c('v-flex',{attrs:{"xs12":"","sm6":"","md3":""}},[_c('v-card',{class:{
            'pa-4 mt-2 mr-2': _vm.$vuetify.breakpoint.xs,
            'pa-4 mr-2 mt-2': _vm.$vuetify.breakpoint.sm,
            'pa-4 mr-2': _vm.$vuetify.breakpoint.mdAndUp,
          },attrs:{"outlined":"","elevation":"20"}},[_c('div',{staticClass:"text-overline mb-2"},[_vm._v("Gastado en año actual")]),_c('div',{staticClass:"text-h6 blue--text text-capitalize"},[_vm._v(" "+_vm._s(_vm._f("currency")(_vm.spentThisYear))+" ")])])],1),_c('v-flex',{attrs:{"xs12":"","md12":"","my-3":""}},[_c('v-card',{staticClass:"pa-2 mr-2",attrs:{"tile":""}},[_c('div',{staticClass:"d-flex align-baseline"},[_c('div',{staticClass:"text-h6 blue--text text-capitalize"},[_vm._v(" Mes actual "+_vm._s(_vm._f("currency")(_vm.TotalMonth))+" ")])]),_c('v-row',{attrs:{"dense":"","justify":"space-between"}},_vm._l((_vm.cards),function(card){return _c('v-col',{key:card.name,attrs:{"cols":"12","md":"4"}},[_c('v-card',{attrs:{"color":card.color,"dark":"","height":"120"}},[_c('div',{staticClass:"d-flex flex-no-wrap justify-space-between"},[_c('div',[_c('v-card-title',{staticClass:"text-h5"},[_c('div',{staticClass:"text-overline white--text"},[_vm._v(" "+_vm._s(card.name)+" ")])]),_c('v-card-subtitle',[_c('div',{staticClass:"text-overline white--text"},[_vm._v(" "+_vm._s(card.dateRange)+" ")]),_c('div',{staticClass:"text-h6 white--text text-capitalize"},[_vm._v(" "+_vm._s(_vm._f("currency")(card.total))+" ")])])],1),(!_vm.isMobile)?_c('v-img',{staticClass:"ma-3",attrs:{"contain":"","max-width":"100","max-height":"100","src":require(`@/assets/${card.pathImage}`)}}):_c('v-img',{staticClass:"ma-3",attrs:{"contain":"","max-width":"60","max-height":"60","src":require(`@/assets/${card.pathImage}`)}})],1)])],1)}),1)],1)],1),_c('v-flex',{attrs:{"xs12":"","md12":"","my-3":""}},[_c('v-flex',[_c('ExpenseTypeStats',{attrs:{"years":_vm.getYears(),"months":_vm.months}})],1)],1),_c('v-flex',{attrs:{"xs12":"","md12":"","my-3":""}},[_c('v-flex',[_c('ExpensesStats',{attrs:{"years":_vm.getYears()}})],1)],1),_c('v-flex',{attrs:{"xs12":"","md12":"","my-3":""}},[_c('v-flex',[_c('ExpensesStatsByType',{attrs:{"years":_vm.getYears()}})],1)],1)],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }