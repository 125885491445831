<template>
  <div>
    <v-card>
      <v-sheet outlined color="blue" rounded="0" max-height="13">
        <br />
      </v-sheet>
      <v-toolbar flat>
        <v-toolbar-title> Modificar información </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon bottom right offset-y @click="closeWindow('cerrar')">
          <v-icon>close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-form ref="infoSelected" @submit.prevent="submit" class="mx-4">
        <v-card-text>
          <v-container>
            <v-layout row wrap justify-space-around>
              <v-flex xs11 md12>
                <v-text-field
                  v-model="infoSelected.displayName"
                  :rules="rules.displayName"
                  label="Nombre Completo"
                  required
                  outlined
                  dense
                  placeholder="Introduce el nombre completo"
                  class="input-group--focused"
                  append-icon="person"
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-container>
        </v-card-text>

        <!-- <v-divider class="mt-12"></v-divider> -->
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn
            :disabled="!formIsValid"
            :loading="isLoading"
            color="primary"
            type="submit"
            small
          >
            <v-icon left> save </v-icon>
            Actualizar
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </div>
</template>
<script>
import apiServices from "../Services/apiServices";

export default {
  data() {
    const defaultForm = Object.freeze({
      displayName: "",
      password: "",
      email: "",
    });

    return {
      form: Object.assign({}, defaultForm),
      isLoading: false,
      rules: {
        displayName: [(val) => (val || "").length > 0 || "Nombre requerido."],
      },
      defaultForm,
    };
  },
  props: {
    infoSelected: null,
  },
  computed: {
    formIsValid() {
      return this.infoSelected.displayName;
    },
  },
  mounted() {
    //  this.isVisible = this.props.patientSelected.isVisible;
    // console.log(this.props.patientSelected);
    // this.patientSelected = this.props.patientSelected;
  },

  methods: {
    resetForm() {
      this.infoSelected = Object.assign({}, this.defaultForm);
      this.$refs.infoSelected.reset();
    },

    submit() {
      this.isLoading = true;
      let item = {
        //id: this.infoSelected.id,
        userName: this.infoSelected.displayName,
        password: "",
        email: this.infoSelected.email,

        userUID: "",
        role: "",
      };

      apiServices.userService
        .UpdateUser(item)
        .then(() => {
          this.closeWindow("editar");
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          if (error.response) {
            // get response with a status code not in range 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // no response
            console.log(error.request);
            // instance of XMLHttpRequest in the browser
            // instance ofhttp.ClientRequest in node.js
          } else {
            // Something wrong in setting up the request
            console.log("Error", error.message);
          }
          console.log("Error.config: " + error.config);
        });
    },
    closeWindow(value) {
      if (value == "nuevo") {
        let result = {
          isVisible: false,
          tipo: "nuevo",
        };
        this.$emit("closeEditInformation", result);
      } else if (value == "editar") {
        let result = {
          isVisible: false,
          tipo: "editar",
        };
        this.$emit("closeEditInformation", result);
      } else if (value == "cerrar") {
        let result = {
          isVisible: false,
          tipo: "cerrar",
        };
        this.$emit("closeEditInformation", result);
      }
      //  this.resetForm();
    },
  },
  created() {},
};
</script>
