import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
// Vuex: for services, shared components, etc
import store from './store/index'
import VueMask from 'v-mask'
import VueSimpleAlert from "vue-simple-alert";
import Echarts from 'vue-echarts';
import 'echarts/lib/chart/bar';
import 'echarts/lib/chart/pie';
import 'echarts/lib/component/legend'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/title'
import 'echarts/theme/dark'
import Vue2Filters from 'vue2-filters'

Vue.use(Vue2Filters)
Vue.component('chart', Echarts);
Vue.use(VueMask);
//Vue.directive('mask', VueMaskDirective);
//Vue.config.productionTip = false
Vue.use(VueSimpleAlert);

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app');
