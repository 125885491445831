<template>
  <div>
    <v-container pb-0>
      <v-layout row wrap>
        <v-flex xs12 md12>
          <v-card class="pa-2 mr-2" tile>
            <div class="d-flex align-baseline">
              <div
                class="blue--text px-2 py-1 text-capitalize font-weight-medium"
              >
                Gastos por tipo de pago
              </div>
              <div class="ml-2 mt-2">
                <v-autocomplete
                  :items="years"
                  dense
                  outlined
                  label="Año"
                  v-model="selectedYear"
                  @change="loaddata"
                  @keydown="$event.target.blur()"
                  @keypress="$event.target.blur()"
                  @keyup="$event.target.blur()"
                ></v-autocomplete>
              </div>
              <div class="ml-2 mt-2">
                <v-autocomplete
                  :items="months"
                  dense
                  outlined
                  label="Mes"
                  item-text="name"
                  item-value="value"
                  v-model="selectedMonth"
                  @change="loaddata"
                  @keydown="$event.target.blur()"
                  @keypress="$event.target.blur()"
                  @keyup="$event.target.blur()"
                ></v-autocomplete>
              </div>
            </div>
            <v-divider></v-divider>
            <v-container>
              <v-layout row wrap my-0>
                <v-flex xs12 md6 mt-4>
                  <v-data-table
                    height="280px"
                    :headers="headers"
                    :items="typesBreakdown"
                    sort-by="type"
                    :items-per-page="5"
                    loading-text="Loading... Please wait"
                    dense
                    :footer-props="{ itemsPerPageOptions: [12] }"
                  >
                    <template v-slot:[`item.value`]="{ item }">
                      <span>{{ item.value | currency }}</span>
                    </template>
                  </v-data-table>
                </v-flex>
                <v-flex xs12 md6 style="min-height:340px; height=100%">
                  <PieChart :chartData="chartDataPieChart" />
                </v-flex>
              </v-layout>
            </v-container>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import apiServices from "../Services/apiServices";
import PieChart from "@/components/Charts/PieChart";

export default {
  props: {
    years: {
      type: Array,
      default() {
        return [];
      },
    },
    months: {
      type: Array,
      default() {
        return [];
      },
    },
    theme: {
      type: String,
    },
  },
  components: {
    PieChart,
  },
  mounted() {
    this.loaddata(this.selectedYear, this.selectedMonth);
  },
  methods: {
    loaddata(year, month) {
      this.loadyearlydata(year, month).then(() => {
        this.loadchart();
      });
    },
    loadyearlydata(year, month) {
      year, month;

      return apiServices.StatsService.GetExpensesGroupByTypeSpent(
        this.selectedYear,
        this.selectedMonth
      ).then((response) => {
        this.fulldata = response.data;
        this.typesBreakdown = this.fulldata["expenses"];
      });
    },
    loadchart() {
      this.chartDataPieChart = {
        labels: this.fulldata["labels"], //["VueJs", "EmberJs", "ReactJs", "AngularJs"],
        datasets: this.fulldata["datasets"],
      };
    },
  },
  computed: {},
  data: () => ({
    selectedYear: new Date().getFullYear(),
    selectedMonth: 0,
    typesBreakdown: [],
    chartDataPieChart: {},
    fulldata: {},

    headers: [
      { text: "Tipo", value: "type" },
      { text: "Total Gastado", value: "value" },
    ],
  }),
};
</script>
