<template>
  <div>
    <LoadingProcess :loadingShow="loadingShow" :loadingTitle="loadingTitle" />
    <v-container pb-0>
      <v-layout row wrap>
        <v-flex xs12 md12>
          <v-card class="pa-2 mr-2" tile>
            <div class="d-flex align-baseline">
              <div
                class="blue--text px-2 py-1 text-capitalize font-weight-medium"
              >
                Gastos por categoria
              </div>
              <div class="ml-2 mt-2">
                <v-autocomplete
                  :items="years"
                  dense
                  outlined
                  label="Año"
                  v-model="selectedYear"
                  @change="loaddata"
                  @keydown="$event.target.blur()"
                  @keypress="$event.target.blur()"
                  @keyup="$event.target.blur()"
                ></v-autocomplete>
              </div>
            </div>
            <v-divider></v-divider>
            <v-container>
              <v-layout row wrap my-0>
                <v-flex xs12 md6 mt-4>
                  <v-data-table
                    height="280px"
                    :headers="headers"
                    :items="yearlyExpenses"
                    sort-by="date"
                    :items-per-page="5"
                    loading-text="Loading... Please wait"
                    dense
                    :footer-props="{ itemsPerPageOptions: [10] }"
                  >
                    <template v-slot:[`item.value`]="{ item }">
                      <span>{{ item.value | currency }}</span>
                    </template>
                  </v-data-table>
                </v-flex>
                <v-flex xs12 md6 style="min-height:340px; height=100%">
                  <StackChart :chartData="chartData" />
                </v-flex>
              </v-layout>
            </v-container>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import apiServices from "../Services/apiServices";
import StackChart from "../components/Charts/StackChart";
import LoadingProcess from "../components/LoadingProcess.vue";

export default {
  props: {
    years: {
      type: Array,
      default() {
        return [];
      },
    },
    theme: {
      type: String,
    },
  },
  components: { StackChart, LoadingProcess },
  mounted() {
    this.loadingTitle = "Cargando estadisticas...";
    this.loaddata(this.selectedYear);
  },
  computed: {},
  methods: {
    loaddata(year) {
      this.loadyearlydata(year).then(() => {
        this.loadcategoryStack(year);
      });
    },
    loadyearlydata(year) {
      year;
      this.loadingShow = true;
      return apiServices.StatsService.GetExpensesGroupByCategory(year).then(
        (response) => {
          this.fulldata = response.data;
          var data = this.fulldata["expenses"];
          data.forEach(function (part) {
            part.date = part.date.substr(0, 10);
            return part.date;
          });
          this.yearlyExpenses = data;
        }
      );
    },
    loadcategoryStack(year) {
      year;
      this.chartData = {
        labels: this.fulldata["labels"],
        datasets: this.fulldata["datasets"],
      };
      this.loadingShow = false;
    },
  },
  data: () => ({
    selectedYear: new Date().getFullYear(),
    yearlyExpenses: [],
    fulldata: {},

    headers: [
      { text: "Categoria", value: "category" },
      { text: "Tipo", value: "type" },
      { text: "Fecha", value: "date" },
      { text: "Cantidad", value: "value" },
    ],
    chartData: {},
    loadingShow: false,
    loadingTitle: "",
  }),
};
</script>

<style></style>
