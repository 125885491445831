import store from '../store/index'

class ExpenseCategoryService {
    axios
    baseUrl
    
    constructor(axios, apiUrl) {
        this.axios = axios
        this.baseUrl = `${apiUrl}ExpensesCategory`
    }
    
    GetExpenseCategoryById(id) {
        let self = this;
        self.axios.defaults.headers.common.Authorization = 'Bearer ' + store.getters.user.token   
        return self.axios.get(`${self.baseUrl}/GetExpenseCategoryById/?id=${id}`);
    }

    GetExpensesCategory() {        
        let self = this;   
        
        self.axios.defaults.headers.common.Authorization = 'Bearer ' + store.getters.user.token   
        return self.axios.get(`${self.baseUrl}/GetExpensesCategory`);
          /*{
            headers: {
              'Authorization': "Bearer " + token
            }
          }*/
    }
    GetExpensesCategoryPagination(filter) {        
      let self = this;   
      
      self.axios.defaults.headers.common.Authorization = 'Bearer ' + store.getters.user.token   
      return self.axios.post(`${self.baseUrl}/GetExpensesCategory`,filter);
        /*{
          headers: {
            'Authorization': "Bearer " + token
          }
        }*/
  }
    /*getAll(params) {        
        let self = this;           
        self.axios.defaults.headers.common.Authorization = 'Bearer ' + store.getters.user.token.token   
        return self.axios.get(`${self.baseUrl}`,{
                params
          });
          /*{
            headers: {
              'Authorization': "Bearer " + token
            }
          }
    }*/

    InsertExpenseCategory(model) {
        let self = this;
        self.axios.defaults.headers.common.Authorization = 'Bearer ' + store.getters.user.token   
        return self.axios.post(`${self.baseUrl}/InsertExpenseCategory`, model);
    }

    UpdateExpenseCategory(model) {
        let self = this;
        self.axios.defaults.headers.common.Authorization = 'Bearer ' + store.getters.user.token   
        return self.axios.put(`${self.baseUrl}/UpdateExpenseCategory`, model);
    }

    DeleteExpenseCategory(id) {
        let self = this;
        self.axios.defaults.headers.common.Authorization = 'Bearer ' + store.getters.user.token   
        return self.axios.delete(`${self.baseUrl}/DeleteExpenseCategory/${id}`);
    }
}

export default ExpenseCategoryService