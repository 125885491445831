<template>
  <div>
    <LoadingProcess :loadingShow="loadingShow" :loadingTitle="loadingTitle" />
    <v-container>
      <v-row align="center" justify="center">
        <v-col cols="12" sm="8">
          <v-card class="elevation-6 mt-10">
            <v-window v-model="step">
              <v-window-item :value="1">
                <v-row>
                  <v-col cols="12" md="6">
                    <v-card-text class="mt-12">
                      <h2 class="text-center">Log in</h2>
                      <h5 class="text-center grey--text">
                        Inicia sesión y disfruta de Expenses Control
                      </h5>
                      <v-row align="center" justify="center">
                        <v-col cols="12" sm="10">
                          <v-text-field
                            v-model="form.email"
                            :rules="rules.email"
                            label="Email"
                            required
                            outlined
                            dense
                            append-icon="mail"
                            class="input-group--focused mt-16"
                            type="email"
                          ></v-text-field>

                          <v-text-field
                            :append-icon="
                              show3 ? 'visibility' : 'visibility_off'
                            "
                            v-model="form.password"
                            :rules="rules.password"
                            label="Password"
                            outlined
                            dense
                            required
                            :type="show3 ? 'text' : 'password'"
                            class="input-group--focused"
                            @click:append="show3 = !show3"
                            @keydown.enter.prevent="submit"
                          ></v-text-field>
                          <v-row>
                            <v-col cols="12" sm="6">
                              <v-checkbox
                                v-model="form.remember"
                                label="Recordarme"
                                class="mt-n1"
                                color="blue"
                              >
                              </v-checkbox>
                            </v-col>
                            <v-col cols="12" sm="6">
                              <span
                                class="caption blue--text"
                                @click="passwordRecovery()"
                                >Recuperar contraseña</span
                              >
                            </v-col>
                          </v-row>
                          <v-btn
                            color="blue"
                            block
                            tile
                            :disabled="!formIsValid"
                            type="submit"
                            class="white--text mt-5"
                            @click="submit()"
                            >Log in</v-btn
                          >
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-col>
                  <v-col cols="12" md="6" class="blue rounded-bl-xl">
                    <div style="text-align: center; padding: 180px 0">
                      <v-card-text class="white--text">
                        <h2 class="text-center">No tienes cuenta todavía?</h2>
                        <h5 class="text-center">
                          Crea una cuenta para acceder a la aplicación<br />
                          no esperes más!!!
                        </h5>
                      </v-card-text>
                      <div class="text-center">
                        <v-btn tile outlined dark @click="stepAsign(1)"
                          >Registrar</v-btn
                        >
                      </div>
                    </div>
                  </v-col>
                </v-row>
              </v-window-item>
              <v-window-item :value="2">
                <v-row>
                  <v-col cols="12" md="6" class="blue rounded-br-xl">
                    <div style="text-align: center; padding: 180px 0">
                      <v-card-text class="white--text">
                        <h2 class="text-center">Ya tienes cuenta?</h2>
                        <h5 class="text-center">
                          Si ya estás registrado, inicia sesión.
                        </h5>
                      </v-card-text>
                      <div class="text-center">
                        <v-btn tile outlined dark @click="stepAsign(2)"
                          >Log in</v-btn
                        >
                      </div>
                    </div>
                  </v-col>

                  <v-col cols="12" md="6">
                    <v-card-text class="mt-12">
                      <h2 class="text-center">Registrate</h2>
                      <h5 class="text-center grey--text">
                        Registrate y disfruta de Expenses Control.
                      </h5>

                      <v-row align="center" justify="center" class="mt-5">
                        <v-col cols="12" sm="8">
                          <v-text-field
                            v-model="registerForm.userName"
                            :rules="rules.userName"
                            label="Nombre Completo"
                            required
                            outlined
                            dense
                            class="input-group--focused"
                            append-icon="person"
                          ></v-text-field>

                          <v-text-field
                            v-model="registerForm.email"
                            :rules="rules.email"
                            label="Email"
                            required
                            outlined
                            dense
                            class="input-group--focused"
                            append-icon="mail"
                          ></v-text-field>

                          <v-text-field
                            :append-icon="
                              show3 ? 'visibility' : 'visibility_off'
                            "
                            v-model="registerForm.password"
                            :rules="rules.password"
                            label="Password"
                            required
                            outlined
                            dense
                            :type="show3 ? 'text' : 'password'"
                            class="input-group--focused"
                            @click:append="show4 = !show4"
                          ></v-text-field>

                          <v-btn
                            color="blue"
                            block
                            tile
                            :disabled="!formRegisterIsValid"
                            @click="register()"
                            class="white--text"
                          >
                            Registrar
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-window-item>
            </v-window>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>

  <!-- <v-card class="mx-auto my-12" elevation="24" max-width="374">
    
  </v-card> -->
</template>

<script>
import apiServices from "../Services/apiServices";
import LoadingProcess from "../components/LoadingProcess.vue";
//import moment from "moment";
import { Base64 } from "js-base64";

export default {
  components: { LoadingProcess },
  data: () => ({
    form: {
      email: "",
      password: "",
      remember: false,
    },
    registerForm: {
      userName: "",
      password: "",
      email: "",
      phone: "",
      specialty: "",
    },
    loadingShow: false,
    loadingTitle: "",
    error: null,
    show3: false,
    show4: false,
    rules: {
      userName: [(val) => (val || "").length > 0 || "Nombre requerido."],
      email: [
        (val) => (val || "").length > 0 || "Email requerido.",
        (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return pattern.test(value) || "Email invalido.";
        },
      ],
      password: [(val) => (val || "").length > 0 || "Password requerido."],
    },
    titleForm: "",
    titleAlert: "",
    subtitleAlert: "",
    step: 1,
  }),
  props: {
    source: String,
  },
  methods: {
    parseJwt(token) {
      var base64Url = token.split(".")[1];
      var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
      var jsonPayload = decodeURIComponent(
        atob(base64)
          .split("")
          .map(function (c) {
            return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
          })
          .join("")
      );

      return JSON.parse(jsonPayload);
    },
    parseJwt2(token) {
      var base64Payload = token.split(".")[1];
      var payload = Buffer.from(base64Payload, "base64");
      return JSON.parse(payload.toString());
    },
    //https://www.youtube.com/watch?v=WYMlEAmQSN4
    submit() {
      this.loadingTitle = "Validando, por favor espere...";
      this.loadingShow = true;
      //valido autenticacion del usuario
      apiServices.LoginService.Authentication(this.form)
        .then((result) => {
          // console.log(result)
          this.message = "";
          if (result.data.token.length) {
            console.log("usuario valido");

            //se determina si se guardan los datos de inicio de sesion
            if (this.form.remember) {
              this.setCookie("username", this.form.email, 30);
              const psd = Base64.encode(this.form.password);
              this.setCookie("psd", psd, 30);
            } else {
              this.setCookie("username", "", 0);
              this.setCookie("psd", "", 0);
            }
            this.saveDataUser(result, true);
            this.$router.replace({
              name: "Stats",
            });
          } else {
            // this.message = "Usuario y/o contraseña incorrectos";
            this.$fire({
              // position: 'top-end',
              title: "Usuario y/o contraseña incorrectos",
              text: "Por favor introduzca la información correcta.",
              type: "error",
              //timer: 3000,
              showConfirmButton: true,
            }).then(() => {
              //console.log(r.value);
            });
          }
          this.loadingShow = false;
        })
        .catch(() => {
          //this.message = "No se pudo acceder al servidor, intente mas tarde";
          this.$fire({
            // position: 'top-end',
            title: "Problemas de conección",
            text: "No se pudo acceder al servidor, intente mas tarde",
            type: "warning",
            //timer: 3000,
            showConfirmButton: true,
          }).then(() => {
            //console.log(r.value);
          });
          this.loadingShow = false;
        });
    },
    stepAsign(value) {
      if (value == 1) {
        this.step++;
      } else if (value == 2) {
        this.step--;
      }
      this.form = {
        email: "",
        password: "",
      };
      this.registerForm = {
        userName: "",
        password: "",
        email: "",
        phone: "",
        specialty: "",
      };
    },
    register() {
      let item = {
        id: "",
        userName: this.registerForm.userName,
        password: this.registerForm.password,
        email: this.registerForm.email,
        phone: this.registerForm.phone,
        specialty: this.registerForm.specialty,
      };

      apiServices.userService
        .InsertUser(item)
        .then(() => {
          //this.resetForm();
          this.$fire({
            position: "top-end",
            title: "Usuario creado correctamente",
            // text: "Operación exitosa!!!",
            type: "success",
            timer: 3000,
            toast: true,
            showConfirmButton: false,
          }).then(() => {
            //console.log(r.value);
          });
          // this.$router.push({
          //   name: "Login",
          // });
          this.stepAsign(2);
        })
        .catch((error) => {
          if (error.response) {
            // get response with a status code not in range 2xx
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            // no response
            console.log(error.request);
            // instance of XMLHttpRequest in the browser
            // instance ofhttp.ClientRequest in node.js
          } else {
            // Something wrong in setting up the request
            console.log("Error", error.message);
          }
          console.log("Error.config: " + error.config);
        });
    },
    passwordRecovery() {
      this.$router.push({
        name: "PasswordRecovery",
      });
    },
    saveDataUser(result, allAccess) {
      let info = this.parseJwt2(result.data.token);

      let user = {
        token: result.data.token,
        loggedIn: result.data.token.length ? true : false,
        loggedInAll: allAccess,
        data: {
          displayName:
            info["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/name"],
          email:
            info[
              "http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress"
            ],
          uid: info[
            "http://schemas.microsoft.com/ws/2008/06/identity/claims/serialnumber"
          ],
          role: info[
            "http://schemas.microsoft.com/ws/2008/06/identity/claims/role"
          ],
        },
      };

      this.$store.dispatch("fetchUser", user);
    },

    //establecer cookie
    setCookie(cName, value, expireDays) {
      let exDate = new Date();
      exDate = exDate.setDate(exDate.getDate() + expireDays);
      document.cookie =
        cName +
        "=" +
        value +
        (expireDays === undefined
          ? ""
          : ";expires=" + new Date(exDate).toUTCString());
    },

    //obtener cookie
    getCookie(key) {
      if (document.cookie.length > 0) {
        var name = key + "=";
        var ca = document.cookie.split(";");

        for (var i = 0; i < ca.length; i++) {
          var c = ca[i].trim();

          if (c.indexOf(name) == 0) {
            // alert("found");

            return c.substr(name.length);
          }
        }
        // alert("not found");
        return null;
        /*let start = document.cookie.indexOf(key + '=');
        if(start !== -1)
        {
          start = start + key.length + 1;
          let end = document.cookie.indexOf(';', start);

          if(end === -1)
          {
            end = document.cookie.length;
            return Base64.decode(document.cookie.substring(start,end));
          }
          else
          {
            return document.cookie.substring(start,end);
          }
        }*/
      }
      return "";
    },

    initForm() {
      const username = this.getCookie("username");
      const psd = this.getCookie("psd");
      if (username && psd) {
        //obtengo password
        let passw = Base64.decode(psd);
        this.form.email = username;
        this.form.password = passw;
        //como existen los datos habilito recordar datos
        this.form.remember = true;
        // this.submit();
      }
    },
  },

  created() {
    this.initForm();
  },

  computed: {
    formIsValid() {
      return this.form.email && this.form.password;
    },
    formRegisterIsValid() {
      return (
        this.registerForm.userName &&
        this.registerForm.email &&
        this.registerForm.password
      );
    },
  },
};
</script>
<style scoped>
.v-application .rounded-bl-xl {
  border-bottom-left-radius: 300px !important;
}
.v-application .rounded-br-xl {
  border-bottom-right-radius: 300px !important;
}
</style>
